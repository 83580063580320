<template>
  <b-skeleton-wrapper :loading="loadingSongs">
    <div id="music-selected-page" class="spotify-style">
      <!-- <b-icon
        class="close-icon"
        icon="x"
        font-scale="1.5"
        title="Close"
        @click="$emit('close')"
      ></b-icon> -->
      <h3 class="text-center album-title">{{ albumSelected.album_name }}</h3>

      <b-row class="songsContent justify-content-center">
        <b-col cols="12" lg="4" xl="4">
          <div class="text-center mb-3 album-cover-container">
            <img
              class="img-fluid album-cover"
              :src="albumSelected.album_thumbnail || albumSelected.thumbnail_url"
              alt="Album Thumbnail"
              title="Album Thumbnail"
            />
          </div>
        </b-col>
        <b-col cols="12" lg="8" xl="8">
          <b-overlay :show="loadingSongs">
            <p class="text-center no-songs" v-if="songs.length === 0">
              No Songs
            </p>
            <div class="songs-list" v-else>
              <div
                class="song-item"
                :class="{
                  'premium': song.is_free === 0 && subscription.class_name === 'free',
                  'active': activeIndex === index
                }"
                v-for="(song, index) in songs"
                :key="index"
                @click="setActiveSong(song, index)"
              >
                <!-- Premium -->
                <div
                  class="itemList justify-items-center p-2"
                  v-if="song.is_free === 0 && subscription.class_name === 'free'"
                  @click="showPremiumModal('song')"
                >
                  <b-row align-v="center" no-gutters>
                    <b-col cols="1">
                      <img
                        class="img-fluid lock"
                        src="../../assets/images/music/lock.png"
                        alt="Lock"
                        title="Locked"
                      />
                    </b-col>
                    <b-col cols="11">
                      <p class="song-name premium-song mb-0" title="Premium Song">
                        {{ song.music_title | truncate(25) }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-overlay class="overlay" variant="transparent" :opacity="0.5" show>
                    <template #overlay>
                      <i class="fas fa-lock" style="color: transparent;"></i>
                    </template>
                  </b-overlay>
                </div>
                <!-- Free -->
                <div class="itemList justify-items-center p-2" v-else>
                  <b-row align-v="center" no-gutters>
                    <b-col cols="1">
                      <img
                        class="img-fluid heart"
                        src="../../assets/images/music/heart-selected.png"
                        alt="Heart"
                        title="Favorite"
                        @click.stop="removeFavorite(song.mobile_music_id)"
                        v-if="song.favorite === 1"
                      />
                      <img
                        class="img-fluid heart"
                        src="../../assets/images/music/heart.png"
                        alt="Heart"
                        title="Favorite"
                        @click.stop="addFavorite(song.mobile_music_id)"
                        v-else
                      />
                    </b-col>
                    <b-col cols="10">
                      <p class="song-name mb-0">
                        {{ song.music_title | truncate(25) }}
                      </p>
                    </b-col>
                    <b-col cols="1">
                      <button class="btnPlayerList" @click.stop="togglePlayPause(song, index)">
                        <i :class="{'fa fa-play': activeIndex !== index, 'fa fa-pause': activeIndex === index}"></i>
                      </button>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
      <!-- Fixed Music Player -->
      <div class="fixed-player" v-if="activeSong.url">
        <div class="container-fluid">
          <div class="playing-info">
            <img :src="activeSong.thumbnail || albumSelected.album_thumbnail || albumSelected.thumbnail_url" alt="Playing" class="playing-thumbnail">
            <div class="playing-details">
              <p class="playing-title mb-0">{{ activeSong.music_title }}</p>
              <p class="playing-artist mb-0">{{ activeSong.artist }}</p>
            </div>
          </div>
          <vue-plyr ref="plyr">
            <audio :src="activeSong.url" controls autoplay></audio>
          </vue-plyr>
        </div>
      </div>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MusicSelectedPage',
  head: {
    title: {
      inner: 'Music',
      separator: '|',
      complement: 'Healthy Hiphop',
    },
  },
  data() {
    return {
      loadingSongs: true,
      songs: [],
      albumSelected: [],
      activeSong: {},
      activeIndex: null,
      totalPlayed: 0,
      status: null,
      player: null,
      currentTime: null,
      streamCount: 0,
      thirtySecSent: false,
      totalSecSent: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'profile/info',
      musicFavorites: 'profile/musicFavorites',
      subscription: 'subscription/subscription',
    }),
  },
  watch: {
    currentTime(newVal, oldVal) {
      let diff = 0;
      if (newVal > oldVal) diff = newVal - oldVal;
      else diff = oldVal - newVal;

      if (diff < 1 && this.status === 'playing') this.totalPlayed = this.totalPlayed + diff;
    },
  },
  methods: {

    getFirstSegment() {
      return this.$route.path.split('/')[1];
    },

    getAlbumSongs(segment) {
      console.log(segment);

      const params = {}
      let endpoint = '';

      if (segment === 'playlist') {
        params.plistId = this.$route.params.id;
        params.page = 1;
        params.perPage = 100;
        endpoint = '/api/playlist/songs';
      }else if (segment === 'album') {
        params.page = 1;
        params.perPage = 1000;
        params.mobileAlbumId = this.$route.params.id;
        endpoint = 'mobile/api/songs';
      }

      this.loadingSongs = true;
      // const params = {
      //   page: 1,
      //   perPage: 1000,
      //   mobileAlbumId: this.$route.params.id,
      // };

      if (this.profile.user_profiles_id) params.profileId = this.profile.user_profiles_id;

      this.$http
        .get(endpoint, {
          params: params,
        })
        .then((response) => {
          if (segment === 'playlist')
            this.songs = response.data.result.data;
          else if (segment === 'album')
            this.songs = response.data.data;
          
          console.log(this.songs, '[---99999---]');
        })
        .catch(() => {
          this.songs = [];
        })
        .finally(() => {
          this.loadingSongs = false;
        });
    },
    getAlbum(segment) {

      const params = {}
      let endpoint = '';

      if (segment === 'playlist') {
        params.plistId = this.$route.params.id;
        endpoint = '/api/playlist';
      }else if (segment === 'album') {
        params.id = this.$route.params.id;
        endpoint = 'mobile/api/album';
      }
      
      this.loadingSongs = true;

      if (this.profile.user_profiles_id) params.profileId = this.profile.user_profiles_id;

      this.$http
        .get(endpoint, {
          params: params,
        })
        .then((response) => {
          console.log(response, '[---000---]');
          if (segment === 'playlist')
            this.albumSelected = response.data.data;
          else if (segment === 'album')
            this.albumSelected = response.data.item;
          // this.albumSelected = this.$route.params.album || '';
          // this.songs = response.data.data;
          console.log(this.albumSelected, '*****');
        })
        .catch(() => {
          //this.songs = [];
        })
        .finally(() => {
          this.loadingSongs = false;
        });
    },
    addFavorite(id) {
      const params = { musicId: id };
      if (this.profile.user_profiles_id) params.profileId = this.profile.user_profiles_id;

      this.$http
        .post('api/mymusic', params)
        .then(() => {
          this.$store.dispatch('profile/getMusicFavorites');
          this.getAlbumSongs();
        })
        .catch(() => {
          this.$notify({
            group: 'notif',
            type: 'error',
            title: 'Failed!',
            text: 'Oops! Something went wrong.',
          });
        });
    },
    removeFavorite(id) {
      const params = { musicId: id };
      if (this.profile.user_profiles_id) params.profileId = this.profile.user_profiles_id;

      this.$http
        .delete('api/mymusic', {
          data: params,
        })
        .then(() => {
          this.$store.dispatch('profile/getMusicFavorites');
          this.getAlbumSongs();
        })
        .catch(() => {
          this.$notify({
            group: 'notif',
            type: 'error',
            title: 'Failed!',
            text: 'Oops! Something went wrong.',
          });
        });
    },
    setActiveSong(song, index) {
      this.activeSong = song;
      this.activeIndex = index;
      this.$nextTick(() => {
        this.$refs.plyr.player.play();
      });
    },
    togglePlayPause(song, index) {

      console.log(song, 'WIXXX');
      if (this.activeIndex === index) {
        if (this.status === 'playing') {
          this.$refs.plyr.player.pause();
        } else {
          this.$refs.plyr.player.play();
        }
      } else {
        this.setActiveSong(song, index);
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', (e) => {
      if (this.totalPlayed >= 30) {
        if (!this.thirtySecSent) this.gtag30sec();
      }

      if (!this.totalSecSent) this.gtagtotalsec();

      delete e['returnValue'];
    });
  },
  mounted() {
    // const segment = this.getFirstSegment();
    // console.log(segment, '-----');
    this.getAlbum(this.$route.params.type);
    this.getAlbumSongs(this.$route.params.type);
    this.$store.dispatch('profile/getMusicFavorites');

    document.addEventListener(
      'timeupdate',
      (e) => {
        const currAudio = e.target.getElementsByTagName('audio')[0];
        this.currentTime = currAudio.currentTime;
      },
      false
    );

    document.addEventListener(
      'playing',
      (e) => {
        this.gtagStartListen();
        this.status = 'playing';
        const currAudio = e.target.getElementsByTagName('audio')[0];
        this.player = currAudio;

        for (let i = 0; i < this.songs.length; i++) {
          if (this.songs[i].url === currAudio.src && this.activeSong !== this.songs[i]) {
            this.activeIndex = i;
            this.activeSong = this.songs[i];
            break;
          }
        }
      },
      false
    );

    document.addEventListener(
      'pause',
      () => {
        if (this.totalPlayed >= 30) {
          if (!this.thirtySecSent) this.gtag30sec();
        }

        if (!this.totalSecSent) this.gtagtotalsec();
        this.status = 'paused';
      },
      false
    );

    document.addEventListener(
      'ended',
      (e) => {
        const currAudio = e.target.getElementsByTagName('audio')[0];
        this.totalPlayed += currAudio.duration;
        this.gtagEndListen();
        this.status = 'ended';
      },
      false
    );
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/settings";

#music-selected-page {
  padding-bottom: 1rem;
  background-image: $music-bg;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center 20%;
  background-size: cover;
  min-height: 100vh;
}

@media only screen and (min-width: $lg-min) {
  #music-selected-page {
    padding-left: $sidebar-width; //padding-left: 7.5rem;
  }
}

@media only screen and (min-width: $special) {
  #music-selected-page {
    padding-left: $sidebar-width; //padding-left: 5rem;
  }
}

@media only screen and (min-width: $xl2-min) {
  #music-selected-page {
    padding-left: $sidebar-width; //padding-left: 8rem;
  }
}

.spotify-style {
  background-color: #ffffff;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  min-height: 100vh; /* Ensure the page has a minimum height */
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  cursor: pointer;
}

.album-title {
  font-size: 2em;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.album-cover-container {
  border: 1px solid #282828;
  border-radius: 5px;
  overflow: hidden;
  position: sticky;
  top: 20px;
}

.album-cover {
  border-radius: 5px;
}

.no-songs {
  font-size: 1.2em;
  color: #ffffff;
}

.songs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.song-item {
  background-color: #282828;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1 1 calc(100% - 10px); /* Two items per row */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.song-item:hover {
  background-color: #383838;
}

.song-item.active {
  background: rgba(29, 185, 84, .8);
}

.song-name {
  font-size: 1em;
  color: #ffffff;
}

.song-name.premium-song {
  color: #f39c12;
}

.heart, .lock {
  width: 20px;
  height: 20px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.fixed-player {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixed-player audio {
  width: 100%;
  max-width: 600px;
}

.playing-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.playing-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}

.playing-details {
  display: flex;
  flex-direction: column;
}

.playing-title {
  font-size: 1em;
  font-weight: bold;
  color: #282828;
}

.playing-artist {
  font-size: 0.9em;
  color: #b3b3b3;
}

.songsContent {
  max-width: 1000px;
  margin: auto;
  padding-bottom: 130px;
}

.itemList {
  width: 100%;
  cursor: pointer;
}

.plyr--audio {
  width: 100%;
  flex: 1;
}

.play-pause-icon {
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}

.btnPlayerList {
  background: transparent;
  border: none;
  color: white;
}

.plyr--audio, .plyr--full-ui, .plyr--audio .plyr__controls, .fixed-player .plyr {
    display: block;
    width: 100%;
}
</style>